// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appVersion: 'v1.0.0',
  USERDATA_KEY: 'authf649fc9a5f55',
  isMockEnabled: false,
  newApiUrl: 'https://crewapi.maritimecloud.ai/api',
  apiUrl: 'https://api.maritimecloud.ai',
  maptiler: {
    apiKey: 'X6joOBrI9dkVrLRQaFKM',
    style: 'https://api.maptiler.com/maps/8e5bef56-dd77-42e7-9907-20d4accf66a1/style.json?key=X6joOBrI9dkVrLRQaFKM'
  },
  openweathermap: {
    apiUrl: 'https://api.openweathermap.org/data/2.5/weather',
    appid: '94027623d0b9bdf60bed7718d7eb1833',
    iconUrl: 'https://openweathermap.org/img/w'
  },
  weatherlayers:{
    token: 'AXRyKvzaq44RhUUEfATB'
  },
  mapbox: {
    accessToken: 'pk.eyJ1IjoiY29uZmlyZSIsImEiOiJjbG42ZDYwZDkwOTUyMnJtaGd2djRscTJ2In0.w-kR0l4cjCv-pNkXspqB0Q',
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

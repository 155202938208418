import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserModel } from '../../models/user.model';
import { environment } from '../../../../../environments/environment';
import { AuthModel } from '../../models/auth.model';

@Injectable({
  providedIn: 'root',
})
export class AuthHTTPService {
  private _newApiUrl = environment.newApiUrl;
  private _apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}

  login(username: string, password: string): Observable<any> {
    return this.http.post<any>(`${this._newApiUrl}/auth/login`, {
      username,
      password,
    });
  }

  loginByToken(token: string): Observable<any> {
    return this.http.get<any>(`${this._newApiUrl}/auth/login-by-token/${token}`);
  }

  logout(): Observable<any> {
    return this.http.get<any>(`${this._newApiUrl}/logout`);
  }

  // CREATE =>  POST: add a new user to the server
  createUser(user: UserModel): Observable<UserModel> {
    return this.http.post<UserModel>(this._newApiUrl, user);
  }

  // Your server should check email => If email exists send link to the user and return true | If email doesn't exist return false
  forgotPassword(email: string): Observable<boolean> {
    return this.http.post<boolean>(`${this._apiUrl}/user/forgotpassword`, {
      email,
    });
  }

  getUserByToken(token: string): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    });
    return this.http.get<any>(`${this._newApiUrl}/userinfo`, {
      headers: httpHeaders,
    });
  }
}
